import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const NewsletterErrorPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t("newsletter.error")} />
      <article className="article text--center">
        <header className="article-header">
          <h1 className="heading heading--md">{t("newsletter.error")}</h1>
        </header>
      </article>
    </Layout>
  )
}

export default NewsletterErrorPage
